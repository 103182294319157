import logo from './logo.svg';
import './App.css';
import React, { useState, useCallback, useEffect } from 'react';

function App() {
  const [message, setMessage] = React.useState()
  React.useEffect(() => {
    var gateway = ("wss://webs.ryutokojou.workers.dev/ws")
    // var gateway = ("ws://127.0.0.1:8787/ws")
    var websocket
    websocket = new WebSocket(gateway);
    window.addEventListener('load', onLoad);
    function initWebSocket() {
      console.log('Trying to open a WebSocket connection...');
      websocket.onopen = onOpen;
      websocket.onclose = onClose;
      websocket.onmessage = onMessage; // <-- add this line
    }
    function onOpen(event) {
      console.log('Connection opened');
      // websocket.send(JSON.stringify({type:"set",value:document.getElementById("count").value}))
    }
    function onClose(event) {
      console.log('Connection closed');
      setTimeout(initWebSocket, 2000);
      websocket = new WebSocket(gateway);
    }
    function onMessage(event) {
      console.log(event.data)
      document.getElementById("debug").innerHTML = event.data
    }
    function onLoad(event) {
      initWebSocket();
    }
    document.getElementById("onbutton").addEventListener("click",()=>{
      // console.log(JSON.stringify({type:"set",value:document.getElementById("count").value}))
      websocket.send(JSON.stringify({type:"run",value:document.getElementById("count").value}))
    })
    document.getElementById("onbutton2").addEventListener("click",()=>{
      // console.log(JSON.stringify({type:"set",value:document.getElementById("count").value}))
      websocket.send(JSON.stringify({type:"all",value:document.getElementById("count2").value}))
    })
  })
  return (
    <div className="App">
      <spna id="debug" ></spna>
      <div><button id='onbutton'>run--</button><input type='text' id='count'></input></div>
      <div><button id='onbutton2'>all--</button><input type='text' id='count2'></input></div>
      <input type='text' value={"UPDATE c SET count=count+1"}></input>
      <input type='text' value={"UPDATE c SET count=0"}></input>
    </div>
  );
}

export default App;
